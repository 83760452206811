import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState, useRef } from "react"
import InputMask from 'react-input-mask'
import api from "../components/api"
import moment from 'moment'
import CurrencyInput from '../components/CurrencyInput'
import DemandaPedido from '../componentsPages/DemandaPedido'
import DemandaAnexos from '../componentsPages/DemandaAnexos'
import { fmtMoney } from "../functions/generalFunctions"
import DemandaCertidoes from '../componentsPages/DemandaCertidoes'
import DemandaDocumentos from '../componentsPages/DemandaDocumentos'


import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "../components/styles.css";

const Compra = (props) => {

    //const { slug } = useParams()
    const slug = props.demanda_id

    const navigate = useNavigate()




    //const demanda_id = props.demanda_id
    const [demanda_id, setDemanda_id] = useState()

    const perfil = sessionStorage.getItem('perfil')


    const usuario_id = sessionStorage.getItem('usuario_id')
    const usuario = sessionStorage.getItem('nome')

    const [usuario_id_comprador, setUsuarioId_comprador] = useState('')

    const [totalHistorico, setTotalHistorico] = useState(0)
    const [totalDocumentos, setTotalDocumentos] = useState(0)

    const [resultadoHistorico, setResultadoHistorico] = useState()

    const [demandaStatus_id, setDemandaStatus_id] = useState('')
    const [demandaStatusAnterior_id, setDemandaStatusAnterior_id] = useState('')

    const [processo, setProcesso] = useState('')
    const [descricao, setDescricao] = useState('')
    const [comprador, setComprador] = useState('')
    const [dataCadastro, setDataCadastro] = useState('')
    const [solicitante, setSolicitante] = useState('')
    const [urgencia, setUrgencia] = useState(false)
    const [valor, setValor] = useState()
    const [numeroProtheus, setNumeroProtheus] = useState('')
    const [dataCompra, setDataCompra] = useState('')
    const [observacao, setObservacao] = useState('')

    const [modalidade_id, setModalidade_id] = useState('')
    const [resultadoModalidades, setResultadoModalidades] = useState('')

    const [resultadoCompradores, setResultadoCompradores] = useState([])
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState([])
    const [resultadoStatus, setResultadoStatus] = useState([])
    const [totalDias, setTotalDias] = useState()

    const [dataContrato, setDataContrato] = useState('')

    const [totalAnexos, setTotalAnexos] = useState(0)
    const [pedidoCompra_id, setPedidoCompra_id] = useState()

    const [totalItensPedido, setTotalItensPedido] = useState(0)
    const [numeroPedido, setNumeroPedido] = useState('')

    const [possuiAnexoAssinatura, setPossuiAnexoAssinatura] = useState()
    const [possuiReservaRecursos, setPossuiReservaRecursos] = useState()
    const [possuiAnexoMapaHomologacao, setPossuiAnexoMapaHomologacao] = useState()
    
    const [analiseTecnica, setAnaliseTecnica] = useState('')

    const [fornecedor_id, setFornecedor_id] = useState()
    const [razaoSocial, setRazaoSocial] = useState('')
    const [cnpjCpf, setCnpjCpf] = useState('')

    const [dataDocumento, setDataDocumento] = useState('')
    const [tipoProcesso, setTipoProcesso] = useState('')
    

    /*    
    const [codigoNatureza, setCodigoNatureza] = useState('')
    const [natureza, setNatureza] = useState('')
    const [resultadoItemContabil, setResultadoItemContabil] = useState()
    const [itemContabil, setItemContabil] = useState('')
    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [resultadoArea, setResultadoArea] = useState()
    const [area, setArea] = useState('')
    const [resultadoSubItem, setResultadoSubItem] = useState()
    const [subItem, setSubItem] = useState('')
    */

    const [valorHomologado, setValorHomologado] = useState()
    const [valorNegociado, setValorNegociado] = useState()

    const [fornecedorVinculado, setFornecedorVinculado] = useState(false)

    
    const totalizaAnexos = () => {

        api.get(`demandaTotalAnexos/${demanda_id}`).then((result) => {


            setTotalAnexos(result.data[0].totalAnexos)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const apaga = () => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o registro?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`demanda/${demanda_id}`).then((result) => {
            
                            //if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                //navigate('/Compras')
                                //window.$('.modal-DemandaCompra').modal('hide')
                                props.show(false)
                                props.carregaDemandas()

                            //}

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }


    const salva = () => {

        /*
        if (novaSenha == '') {

            window.toastr.error('<b>Senha</b> não pode ficar em branco!')
            return false

        }

        */


        if (modalidade_id == '' || modalidade_id == null) {

            window.toastr.error('<b>Modalidade</b> não pode ficar em branco!')
            return false

        }


        if (processo == '') {

            window.toastr.error('<b>Processo</b> não pode ficar em branco!')
            return false

        }

        if (solicitante == '') {

            window.toastr.error('<b>Solicitante</b> não pode ficar em branco!')
            return false

        }

        if (usuario_id_comprador == '') {

            window.toastr.error('<b>Comprador</b> não pode ficar em branco!')
            return false

        }

        //console.log(dataCompra, '!!!')

        if (dataCompra != '' && dataCompra != null) {
                
            if (moment(dataCompra, "DD/MM/YYYY", true).isValid() == false) {
                

                window.toastr.error('<b>Data de compra</b> inv&aacute;lida!');
                return false;

            }

        }

        

        if (dataDocumento != '') {
                
            if (moment(dataDocumento, "DD/MM/YYYY", true).isValid() == false) {
                

                window.toastr.error('<b>Data do documento</b> inv&aacute;lida!');
                return false;

            }

        }

        

        if (demandaStatus_id == '') {

            window.toastr.error('<b>Fase</b> não pode ficar em branco!')
            return false

        } else {

            //console.log(demandaStatus_id)


            if ((demandaStatus_id == 9 || demandaStatus_id == 10) && (numeroProtheus == '' || numeroProtheus == null)) {

                window.toastr.error('<b>N<sup>o<sup> </b> Protheus não pode ficar em branco!');
                return false;

            }

            if (demandaStatus_id == 10) {


                if (dataContrato != '') {

                    //window.toastr.error('Por favor, informe a <b>data da assinatura do contrato</b>!')
                    //return false;

                    if (moment(dataContrato, "DD/MM/YYYY", true).isValid() == false) {

                        window.toastr.error('<b>Data da assinatura do contrato</b> inválida!')
                        return false;

                    }

                }

                

            }


        }
        
        var dataCompraSQL = ''
        var dataContratoSQL = ''
        var dataDocumentoSQL = ''

        //console.log(dataCompra)
        //return false

        if (dataCompra != '' && dataCompra != null) {

            //var dataCompraArray = dataCompra.split('/')
            //var dataCompraSQL = dataCompraArray[2] + '-' + dataCompraArray[1] + '-' + dataCompraArray[0]

            var dataCompraArray = moment(dataCompra).format('DD/MM/YYYY').split('/')
            var dataCompraSQL = dataCompraArray[2] + '-' + dataCompraArray[1] + '-' + dataCompraArray[0]
        }

        if (dataContrato != '' && dataContrato != null) {

            var dataContratoArray = moment(dataContrato).format('DD/MM/YYYY').split('/')
            var dataContratoSQL = dataContratoArray[2] + '-' + dataContratoArray[1] + '-' + dataContratoArray[0]

        }

        if (dataDocumento != '' && dataDocumento != null) {

            var dataDocumentoArray = moment(dataDocumento).format('DD/MM/YYYY').split('/')
            var dataDocumentoSQL = dataDocumentoArray[2] + '-' + dataDocumentoArray[1] + '-' + dataDocumentoArray[0]

        }
        
        //return false


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {


                    if (demanda_id) {

                        var dataPost = {

                            demandaStatus_id: demandaStatus_id,
                            demandaStatusAnterior_id: demandaStatusAnterior_id,
                            processo: processo,
                            descricao: descricao,
                            //comprador: comprador,
                            usuario_id_comprador: usuario_id_comprador,
                            usuario_id: usuario_id,
                            solicitante: solicitante,
                            urgencia: urgencia,
                            //dataCadastro: dataCadastro,
                            valor: valor ? valor.replaceAll('.', '').replaceAll(',','.') : 0,
                            numeroProtheus: numeroProtheus,
                            dataCompra: dataCompraSQL,
                            dataContrato: dataContratoSQL,
                            observacao: observacao,
                            usuario: usuario,
                            modalidade_id: modalidade_id,
                            dataDocumento: dataDocumentoSQL,

                        }

                        //console.log(dataPost)
                        //return false

                        api.put(`demanda/${demanda_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            //return false
                            //limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            //window.$('.modal-DemandaCompra').modal('hide')
                            //carregaTotalHistoricoDemanda()

                            carregaHistoricoDemanda()
                            setDemandaStatusAnterior_id(demandaStatus_id)
                            props.carregaDemandas()
                            return false
    
                        }).catch((err) => {
    
                            console.log(err.response)
    
                        })

                    } else {

                        var dataPost = {

                            demandaStatus_id: demandaStatus_id,
                            processo: processo,
                            descricao: descricao,
                            //comprador: comprador,
                            usuario_id_comprador: usuario_id_comprador,
                            usuario_id: usuario_id,
                            solicitante: solicitante,
                            urgencia: urgencia,
                            //dataCadastro: dataCadastro,
                            valor: valor ? valor.replaceAll('.', '').replaceAll(',','.') : 0,
                            numeroProtheus: numeroProtheus,
                            dataCompra: dataCompraSQL,
                            dataContrato: dataContratoSQL,
                            observacao: observacao,
                            usuario: usuario,
                            modalidade_id: modalidade_id,
                
                        }
                
                        //console.log(dataPost)

                        api.post(`demanda`, dataPost).then((result) => {

                            //console.log(result.data)
                            //return false
                            limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            //window.$('.modal-DemandaCompra').modal('hide')
                            setDemandaStatusAnterior_id(demandaStatus_id)
                            props.carregaDemandas()
                            return false
    
                        }).catch((err) => {
    
                            console.log(err.response)
    
                        })

                    }

                    /*
                                
                    


                    */
                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }

    const limpa = () => {

        
        setDemandaStatus_id('')
        setProcesso('')
        setDescricao('')
        setComprador('')
        setDataCadastro('')
        setSolicitante('')
        setUrgencia(false)
        setValor('')
        setNumeroProtheus('')
        setDataCompra('')
        setDataContrato('')
        setObservacao('')
        setTotalHistorico(0)
        setTotalDocumentos(0)
        setDemandaStatusAnterior_id('')
        setModalidade_id('')
        setUsuarioId_comprador('')
        setPossuiAnexoAssinatura()
        setPossuiReservaRecursos()
        setTipoProcesso('')
        setDataDocumento('')

        /*
        setArea('')
        setSubItem('')
        setCodigoNatureza('')
        setItemContabil('')
        setValorAutorizado('')
        setNatureza('')
        setItemContabilDescri('')
        */

        setValorHomologado('')
        setValorNegociado('')
        setAnaliseTecnica('')
        setFornecedorVinculado(false)
        setFornecedor_id()
        setCnpjCpf('')
        setRazaoSocial('')
    }


    


    const carregaModalidades = () => {

        api.get('modalidades').then((result) => {

            setResultadoModalidades(result.data.map((rs) => 

                <option value={rs.modalidade_id}>{rs.modalidade}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

            setResultadoSolicitantes(result.data.map((rs) => 

                <option value={rs.solicitante}>{rs.solicitante}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const carregaCompradores = () => {

        api.get('compradores').then((result) => {

            setResultadoCompradores(result.data.map((rs) => 

                <option value={rs.usuario_id}>{rs.nome}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const totalizaDocumentos = () => {

        api.get(`demandaDocumentoUpload/${demanda_id}`).then((result) => {

            setTotalDocumentos(result.data.length)

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const carregaStatus = () => {

        api.get('statusDemanda').then((result) => {

            setResultadoStatus(result.data.map((rs) => 

                <option value={rs.demandaStatus_id}>{rs.fase}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    /*
    const carregaTotalHistoricoDemanda = () => {

        api.get(`totalHistoricoDemanda/${demanda_id}`).then((result) => {
        
            setTotalHistorico(result.data[0].total)

        }).catch((err) => {
            
            console.log(err.response)
            
        })

    }
    */

    useEffect(() => {

        limpa()
        

    }, [])


    useEffect(() => {

        window.$('[href="#tabs-4-tab-1k"]').tab('show');
        

        carregaSolicitantes()
        carregaCompradores()
        carregaStatus()
        carregaModalidades()
        totalizaAnexos()
        totalizaDocumentos()
       
    }, [demanda_id])


    /*
    useEffect(() => {

        console.log(possuiReservaRecursos, 'possuiReserva')

    }, [possuiReservaRecursos])
    */



    const carregaDemanda = () => {

        if (demanda_id) {

                
            api.get(`demanda/${demanda_id}`).then((result) => {

                //console.log(result.data, 'demanda aqui')

                var data = result.data[0]

                //console.log(data)
                
                setValorHomologado(data.valorHomologado ? fmtMoney(data.valorHomologado, 2).replaceAll('R$', '') : '')
                setValorNegociado(data.valorNegociado ? fmtMoney(data.valorNegociado, 2).replaceAll('R$', '') : '')
                setValor(data.valor ? fmtMoney(data.valor, 2).replaceAll('R$', '') : '')

                //console.log(data.dataCompra, 'aqui data')
                if (data.dataCompra) {

                    var dateParts = data.dataCompra.split("/");
                    var dataCompra = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

                }

                setDataCompra(dataCompra)

                setAnaliseTecnica(data.analiseTecnica)

                setFornecedor_id(data.fornecedor_id)
                setRazaoSocial(data.razaoSocial)
                setCnpjCpf(data.cnpjCpf)

                setFornecedorVinculado(data.fornecedor_id ? true : false)

                setPossuiAnexoAssinatura(data.possuiAnexoAssinatura)
                //setPossuiReservaRecursos(data.possuiReservaRecursos)
                setPossuiAnexoMapaHomologacao(data.possuiAnexoMapaHomologacao)
                
                setDemandaStatus_id(data.demandaStatus_id)
                setDemandaStatusAnterior_id(data.demandaStatus_id)
                setComprador(data.responsavel)
                setUsuarioId_comprador(data.usuario_id_comprador)
                setPedidoCompra_id(data.pedidoCompra_id)
                //setFase(data.fase)
                setProcesso(data.processo)
                setDescricao(data.descricao)
                setComprador(data.comprador)
                setSolicitante(data.solicitante)
                setUrgencia(data.urgencia)
                setDataCadastro(data.dataCadastro)

                

                var dataContrato = data.dataContrato

                if (data.dataContrato != '' && data.dataContrato != null) {

                    var dateParts = data.dataContrato.split("/");
                    var dataContrato = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

                }
                setDataContrato(dataContrato)

   

                setNumeroProtheus(data.numeroProtheus)
                
                setObservacao(data.observacao)
                setModalidade_id(data.modalidade_id)




                var dataDocumento = data.dataDocumento

                if (data.dataDocumento != '' && data.dataDocumento != null) {

                    var dateParts = data.dataDocumento.split("/");
                    var dataDocumento = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

                }
                setDataDocumento(dataDocumento)

                setTipoProcesso(data.tipoProcesso)

                /*
                setCodigoNatureza(data.codigoNatureza)
                setItemContabil(data.itemContabil)                
                
                setArea(data.area)
                
                setSubItem(data.subItem)
                */
                


                


            }).catch((err) => {

                console.log(err.response)

            })

        }

    }




    const carregaHistoricoDemanda = () => {

        api.get(`historicoDemandaLista/${demanda_id}`).then((result) => {

            
            setTotalHistorico(result.data[0].length)
            setTotalDias(result.data[1][0].totalDias)
            setResultadoHistorico(result.data[0].map((rs) =>
            
                <tr style={{ fontSize: '11px', textAlign: 'center'}}>
                    <td>

                        {rs.data}
                    </td>
                    <td>
                        {rs.fase}
                    </td>
                    <td>
                        {rs.usuario}
                    </td>
                    <td>
                        {rs.observacao}
                    </td>
                    <td>
                        {rs.diferencaDias}
                    </td>
                </tr>
            
            ))

        }).catch((err) => {

            console.log(err.response)


        })

    }




    async function geraPDFEncerramento() {


        await api.get(`termoEncerramentoPDF/${demanda_id}`).then((result) => {

            if (result.data) {

                //window.open(`https://api-faj.koderia.com.br/pdf/${result.data.arquivo}`)
                //window.open(`http://192.168.255.165:3378/pdf/${result.data.arquivo}`)
                //window.open(`https://apiportaltransp.fajsaude.com.br/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)

            }

            
        }).catch((err) => {

            console.log(err.response)

        })

    }




    const salvaHomologacao = () => {

        

        if (valorHomologado == '' || valorHomologado == null) {

            window.toastr.error('<b>Valor homologado</b> não pode ficar em branco!')
            return false;

        }


        if (valorNegociado == '' || valorNegociado == null) {

            window.toastr.error('<b>Valor negociado</b> não pode ficar em branco!')
            return false;

        }

        console.log(analiseTecnica, 'analise')

        if (analiseTecnica != false && analiseTecnica != true && analiseTecnica != 'false' && analiseTecnica != 'true') {


            window.toastr.error('Por favor, informe se haverá <b>Análise Técnica</b>!')
            return false;


        }



        var dataPost = {

            valorHomologado: valorHomologado ? valorHomologado.replaceAll('.', '').replaceAll(',','.') : 0,
            valorNegociado: valorNegociado ? valorNegociado.replaceAll('.', '').replaceAll(',','.') : 0,
            analiseTecnica: analiseTecnica,
            usuario: usuario,
            demandaStatusAtual: demandaStatus_id,

        }

        //console.log(dataPost)


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
            title: 'Salvar',
            content: 'Confirma salvar os dados da homologação?',
            buttons: {
                Ok: function () {

                    api.put(`demandaHomologacao/${demanda_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        
                        carregaDemanda()
                        carregaHistoricoDemanda()
                        window.toastr.success('Salvo com sucesso!')


                    }).catch((err) => {

                        console.log(err)

                    })

                },
                Cancelar: function () {


                },
            },

        });


    }



    useEffect(() => {

        setDemanda_id(slug)


    }, [slug])


    useEffect(() => {

        limpa()
        carregaDemanda()
        carregaHistoricoDemanda()
        //carregaTotalHistoricoDemanda()

        window.$('#accordion').find('#collapseOne').collapse('hide')
        window.$('#accordion').find('#collapseTwo').collapse('hide')
        window.$('#accordion').find('#collapseThree').collapse('hide')
        window.$('#accordion').find('#collapseFour').collapse('hide')
        window.$('#accordion').find('#collapseFive').collapse('hide')
        window.$('#accordion').find('#collapseSix').collapse('hide')
        window.$('#accordion').find('#collapseSeven').collapse('hide')
        
        

    }, [demanda_id])




    return (


        <div>

            

                <section className="tabs-section">
                    <div className="tabs-section-nav tabs-section-nav-inline">
                        <ul className="nav" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" href="#tabs-4-tab-1k" role="tab" data-toggle="tab">
                                    Detalhes
                                </a>
                            </li>
                            <li className="nav-item" style={{ display: pedidoCompra_id ? 'table-row' : 'none' }} >
                                <a className="nav-link" href="#tabs-4-tab-3k" role="tab" data-toggle="tab">
                                    Solicitação
                                    
                                </a>
                            </li>
                            <li className="nav-item" style={{ display: demanda_id ? 'table-row' : 'none' }}>
                                <a className="nav-link" href="#tabs-4-tab-4k" role="tab" data-toggle="tab">
                                    Anexos&nbsp;<span class="label label-pill label-info">{totalAnexos}</span>
                                    
                                </a>
                            </li>

                            <li className="nav-item" style={{ display: possuiAnexoMapaHomologacao ? 'table-row' : 'none' }}>
                                <a className="nav-link" href="#tabs-4-tab-5k" role="tab" data-toggle="tab">
                                    Homologação
                                    
                                </a>
                            </li>

                            <li className="nav-item" style={{ display: demanda_id ? 'table-row' : 'none' }}>
                                <a className="nav-link" href="#tabs-4-tab-6k" role="tab" data-toggle="tab">
                                    Certidões
                                    
                                </a>
                            </li>

                            <li className="nav-item" style={{ display: demanda_id ? 'table-row' : 'none' }}>
                                <a className="nav-link" href="#tabs-4-tab-2k" role="tab" data-toggle="tab">
                                    Hist&oacute;rico&nbsp;<span class="label label-pill label-danger">{totalHistorico}</span>
                                    
                                </a>
                            </li>

                            <li className="nav-item" style={{ display: demanda_id ? 'table-row' : 'none' }}>
                                <a className="nav-link" href="#tabs-4-tab-7k" role="tab" data-toggle="tab">
                                    Documentos&nbsp;<span class="label label-pill label-success">{totalDocumentos}</span>
                                    
                                </a>
                            </li>
                            
                            
                        </ul>
                    </div>

                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4-tab-1k">

                        <div className="row">
                            <div className="col-md-3">
                                <fieldset className="form-group">
                                    <label className="form-label">Data cadastro</label>
                                    <input type="text" className="form-control" value={dataCadastro} disabled />
                                </fieldset>
                            </div>
                            <div className="col-md-5">
                                <fieldset className="form-group">
                                    <label className="form-label">Processo *</label>
                                    <input type="text" className="form-control" value={processo}  onChange={event => setProcesso(event.target.value)} maxLength={150} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' || tipoProcesso == 'D' ? true : false }/>
                                </fieldset>
                            </div>
                            <div className="col-md-4">
                                <fieldset className="form-group">
                                    <label className="form-label">N<sup>o</sup> Protheus</label>
                                    <input type="text" className="form-control" value={numeroProtheus}  onChange={event => setNumeroProtheus(event.target.value)} maxLength={150} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }/>
                                </fieldset>
                            </div>
                        
                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError">Descrição *</label>
                                    <textarea rows="3" className="form-control" value={descricao} onChange={event => setDescricao(event.target.value)} maxLength={1000} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }/>
                                        
                                    
                                </fieldset>
                            </div>

                            <div className="col-md-6">
                                <fieldset className="form-group">
                                    <label className="form-label" >Solicitante *</label>
                                    <select className="form-control" value={solicitante} onChange={event => setSolicitante(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }>
                                        <option value="">[Selecione]</option>
                                        {resultadoSolicitantes}
                                    
                                    </select>
                                        
                                    
                                </fieldset>
                            </div>
                            <div className="col-md-6">
                                <fieldset className="form-group">
                                    <label className="form-label" >Comprador *</label>
                                    <select className="form-control" value={usuario_id_comprador} onChange={event => setUsuarioId_comprador(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }>
                                        <option value="">[Selecione]</option>
                                        {resultadoCompradores}
                                    
                                    </select>
                                        
                                    
                                </fieldset>
                            </div>
                            <div className="col-md-6">
                                <fieldset className="form-group">
                                    <label className="form-label" >Urgente</label>
                                    <select className="form-control" value={urgencia} onChange={event => setUrgencia(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }>
                                        <option value={false}>Não</option>
                                        <option value={true}>Sim</option>
                                    </select>
                                        
                                    
                                </fieldset>
                            </div>
                            <div className="col-md-6">
                                <fieldset className="form-group">
                                    <label className="form-label" >Valor R$</label>
                                    <CurrencyInput type="text" placeholder="0,00" className="form-control" value={valor}  onChange={event => setValor(event.target.value)} maxLength={150} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false } />
                                
                                </fieldset>
                            </div>
                        
                            <div className="col-md-8">
                                <fieldset className="form-group">
                                    <label className="form-label" >Modalidade *</label>
                                    <select className="form-control" value={modalidade_id} onChange={event => setModalidade_id(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false } >
                                        <option value="">[Selecione]</option>
                                        {resultadoModalidades}
                                    
                                    </select>
                                        
                                    
                                </fieldset>
                            </div>

                            <div className="col-md-4">
                                <fieldset className="form-group">
                                    <label className="form-label" >Data compra</label>

                                    <DatePicker   selected={dataCompra || null} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }  onChange={(date) => setDataCompra(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: demanda_id ? '#FFF' : '#FFF'}} /> } 
                                    />

                                
                                        
                                    
                                </fieldset>
                            </div>
                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >Observação</label>
                                    <textarea type="text" className="form-control" value={observacao} maxLength={2500} rows="2" onChange={event => setObservacao(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false } />
                                
                                        
                                    
                                </fieldset>
                            </div>

                            <div className="col-md-3" >
                                <fieldset className="form-group">
                                    <label className="form-label" >Data do documento *</label>
                                
                                    <DatePicker   selected={dataDocumento || null} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }  onChange={(date) => setDataDocumento(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: demanda_id ? '#FFF' : '#FFF'}} /> } 
                                    />
                                    
                                </fieldset>
                            </div>

                            <div className="col-md-3" >
                                <fieldset className="form-group">
                                    <label className="form-label" >Data assinatura contrato</label>
                                
                                    <DatePicker   selected={dataContrato || null} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }  onChange={(date) => setDataContrato(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: demanda_id ? '#FFF' : '#FFF'}} /> } 
                                    />
                                    
                                </fieldset>
                            </div>



                            <div className="col-md-6">
                                <fieldset className="form-group">
                                    <label className="form-label" >Fase *</label>
                                    <select className="form-control" value={demandaStatus_id} style={{ backgroundColor: '#d6c7ed'}} onChange={event => setDemandaStatus_id(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }>
                                        <option value="">[Selecione]</option>
                                        {resultadoStatus}
                                    
                                    </select>
                                        
                                    
                                </fieldset>
                            </div>


                    </div>

                
                        

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-2k">


                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                            <thead>
                                    <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                        
                                        <th>
                                            
                                        Data                                 

                                        </th>
                                        <th>
                                            
                                        Fase                                 

                                        </th>
                                        <th>
                                            
                                        Usuário                                 

                                        </th>
                                        <th>
                                            
                                        Obs.                                 

                                        </th>
                                        <th>
                                            
                                        Dias                                 

                                        </th>
                                    </tr>
                                    
                            </thead>
                            {resultadoHistorico}
                            <tr>
                                <th colspan="4" style={{ fontSize: '11px', textAlign: 'right'}}>
                                    Total
                                </th>
                                <th style={{ fontSize: '11px', textAlign: 'center'}}>{totalDias}</th>
                            </tr>
                        </table>
                

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-3k">
                        
                        <DemandaPedido demanda_id={demanda_id} possuiAnexoAssinatura={possuiAnexoAssinatura} possuiReservaRecursos={possuiReservaRecursos} pedidoCompra_id={pedidoCompra_id} />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-4k">
                        
                        <DemandaAnexos  demanda_id={demanda_id} totalizaAnexos={totalizaAnexos} carregaDemanda={carregaDemanda} carregaHistoricoDemanda={carregaHistoricoDemanda} setDemandaStatus_id={setDemandaStatus_id} demandaStatus_id={demandaStatus_id} setDemandaStatusAnterior_id={setDemandaStatusAnterior_id} processo={processo} setPossuiAnexoAssinatura={setPossuiAnexoAssinatura} setPossuiReservaRecursos={setPossuiReservaRecursos} setPossuiAnexoMapaHomologacao={setPossuiAnexoMapaHomologacao} />

                    </div>


                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-5k">
                        
                        <div className="row">


                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >Valor homologado R$</label>
                                    <CurrencyInput type="text" placeholder="0,00" className="form-control" value={valorHomologado} onChange={event => setValorHomologado(event.target.value)} maxLength={150} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro')  ? true : false } />
                                
                                </fieldset>
                            </div>

                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >Valor negociado R$</label>
                                    <CurrencyInput type="text" placeholder="0,00" className="form-control" value={valorNegociado} onChange={event => setValorNegociado(event.target.value)} maxLength={150} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro')  ? true : false } />
                                
                                </fieldset>
                            </div>

                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >Haverá análise técnica?</label>
                                    <select className="form-control" value={analiseTecnica} onChange={event => setAnaliseTecnica(event.target.value)} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro')  ? true : false } >

                                        <option value="">[Selecione]</option>
                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>

                                    </select>
                                
                                </fieldset>
                            </div>


                            <div className="col-md-12">

                                <div align="left">

                                    <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvaHomologacao()} style={{ display: perfil == 'Administrador' || perfil == 'Compras' ? 'table-row' : 'none' }} >Salvar informações homologação</button>

                                </div>


                            </div>

                        </div>

                    </div>

                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-6k">
                        
                        <DemandaCertidoes demanda_id={demanda_id} fornecedor_id_props={fornecedor_id} cnpjCpf_props={cnpjCpf} razaoSocial_props={razaoSocial} fornecedorVinculado_props={fornecedorVinculado} />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-7k">
                        
                        <DemandaDocumentos demanda_id={demanda_id} totalizaDocumentos={totalizaDocumentos} />

                    </div>
                    
                    
                </div>
            </section>

            <div className='row'>
                <div className="col-md-12">

                    <button type="button" className="btn btn-inline btn-default-outline"  onClick={() => props.show(false)}>Fechar</button>
                    <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => geraPDFEncerramento()} style={{ display: demanda_id != null && demandaStatus_id == 10 && (perfil == 'Compras' || perfil == 'Administrador') ? 'table-row' : 'none' }} >Termo de encerramento</button>
                    <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => apaga()} style={{ display: demanda_id != null && perfil == 'Administrador' ? 'table-row' : 'none' }} >Apagar</button>
                    <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()} style={{ display: perfil == 'Administrador' || perfil == 'Compras' ? 'table-row' : 'none' }} >Salvar</button>
                

                </div>
            </div>


    </div>

    )


}

export default Compra