import { useEffect, useMemo, useState } from 'react'
import api from '../components/api'
import { fmtMes, fmtMoney, sleep } from '../functions/generalFunctions'
import moment from 'moment'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

import "../components/styles.css";

registerLocale('ptBR', ptBR)

const Demonstrativo = () => {

    //var dataInicio = `${moment().format('YYYY')}-${moment().format('MM')}-01`
    var dataInicio = `${moment().format('YYYY')}-01-01`

    var dataInicio = moment(dataInicio, 'YYYY-MM-DD').toDate()
    

    const [startDate, setStartDate] = useState(dataInicio);
    const [endDate, setEndDate] = useState(new Date())

    


    const anoAtual = moment().year()
    const mesAtual = moment().month() + 1

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()

    const [ano, setAno] = useState(anoAtual.toString())
    
    const [mesInicio, setMesInicio] = useState('1')
    const [mesFim, setMesFim] = useState(mesAtual.toString())

    const [dataUltimoMovimento, setDataUltimoMovimento] = useState()
    const [resultadoReceitas, setResultadoReceitas] = useState([])
    const [resultadoDespesas, setResultadoDespesas] = useState([])

    const [resultadoTotalReceitas, setResultadoTotalReceitas] = useState([])
    const [resultadoTotalDespesas, setResultadoTotalDespesas] = useState([])
    const [resultadoTotalGeral, setResultadoTotalGeral] = useState([])

    const [resultadoOperacionalDiversos, setResultadoOperacionalDiversos] = useState([])
    const [resultadoEspeciais, setResultadoEspeciais] = useState([])

    const [tipoDemonstrativo, setTipoDemonstrativo] = useState('T')

    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }

    const consultaDataUltimoMovimento = () => {


        api.get('listaDataMovimento').then((result) => {

            //console.log(result.data)      
            setDataUltimoMovimento(result.data[0].dataMovimento)      

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const carregaReceitas = async () => {

        

        /*

        if (parseInt(mesFim) < parseInt(mesInicio)) {
            //console.log(mesInicio, mesFim)
            //console.log('aqui')

            window.toastr.error('Mês término não pode ser menor que o Mês início!')
            return false

        } 

        */



        //if (mesInicio && ano) {

            var dataPost = {

                ano: ano,
                mes: mesInicio,
                mesFim: mesFim,
                tipo: 'RECEITAS',
                tipoDemonstrativo: tipoDemonstrativo,
                dataInicio: moment(startDate).format('YYYY-MM-DD'),
                dataFim: moment(endDate).format('YYYY-MM-DD'),
    
            }

            //console.log(dataPost)
            //return false
    
            await api.post('demonstrativoFinanceiro', dataPost).then((result) => {
    
                //console.log(result.data[0], '!!')
                //return false

                //console.log(result.data)

                setResultadoTotalReceitas(result.data[1].map((rs) =>
                
                    <tr style={{ fontSize: '11px', textDecoration: 'bold', backgroundColor: '#EFEFEF'}}>
                        <th colspan="2" style={{ textAlign: 'center'}}>
                            Total
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalRealizado != null ? fmtMoney(rs.totalRealizado, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalRealizar != null ? fmtMoney(rs.totalRealizar, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalTotal != null ? fmtMoney(rs.totalTotal, 0).toString().replaceAll('R$', '') : 0}
                            
                        </th>
                        <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalOrcado != null ? fmtMoney(rs.totalOrcado, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalAlcance != null ? fmtMoney(rs.totalAlcance, 0).toString().replaceAll('R$', '') : 0}%
                        </th>


                    </tr>
                                
                ))

    
                setResultadoReceitas(result.data[0].map((rs) =>
                
                    <tr style={{ fontWeight: rs.fluxoCaixa == 'Total' ? 'bold' : '', fontSize: '11px', backgroundColor: rs.fluxoCaixa == 'Total' ? '#efefef' : '#edf8fa' }}>
    
                        <td style={{ textAlign: 'center'}}>
                            {rs.classe}
                        </td>
                        <td>
                            {rs.fluxoCaixa}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.realizado != null ? fmtMoney(rs.realizado, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.realizar != null ? fmtMoney(rs.realizar, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.total != null ? fmtMoney(rs.total, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.orcado != null ? fmtMoney(rs.orcado, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.alcance != null ? fmtMoney(rs.alcance, 0).toString().replaceAll('R$', '') : 0}%
                        </td>
    
                    </tr>
                
                ))


    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        //  }

        

    }


    const carregaTotais = () => {

        //console.log('aqui')

        var dataPost = {

            mes: mesInicio,
            mesFim: mesFim,
            ano: ano,
            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),

        }

        
        api.post('demonstrativoFinanceiroTotal', dataPost).then((result) => {

            //console.log(result.data, '!!')

            setResultadoTotalGeral(result.data.map((rs) =>

                

                    <tr style={{ fontSize: '11px', backgroundColor: '#dae6e8'}}>
                        <th colspan="2" style={{ textAlign: 'center'}}>
                            Total
                        </th>
                        <th style={{ textAlign: 'right', color: rs.realizado < 0 ? 'red' : 'blue' }}>
                            {rs.realizado != null ? fmtMoney(rs.realizado, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right', color: rs.realizar < 0 ? 'red' : 'blue'}}>
                            {rs.realizar != null ? fmtMoney(rs.realizar, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right', color: rs.total < 0 ? 'red' : 'blue'}}>
                            {rs.total != null ? fmtMoney(rs.total, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                        <th style={{ textAlign: 'right', color: rs.orcado < 0 ? 'red' : 'blue'}}>
                            {rs.orcado != null ? fmtMoney(rs.orcado, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right', color: rs.alcance < 0 ? 'red' : 'blue'}}>
                            {rs.alcance != null ? fmtMoney(rs.alcance, 0).toString().replaceAll('R$', '') : 0}%
                        </th>
                        

                    </tr>

                
            ))



            setResultadoOperacionalDiversos(result.data.map((rs) => 
                

                <tr style={{ fontSize: '11px', backgroundColor: '#dae6e8'}}>
                    <th colspan="2" style={{ textAlign: 'center'}}>
                        Total Operacional + Diversos + Pessoal + OPME
                    </th>
                    <th style={{ textAlign: 'right', color: rs.realizadoOperacionalDiversos < 0 ? 'red' : 'blue' }}>
                        {rs.realizadoOperacionalDiversos != null ? fmtMoney(rs.realizadoOperacionalDiversos, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.realizarOperacionalDiversos < 0 ? 'red' : 'blue'}}>
                        {rs.realizarOperacionalDiversos != null ? fmtMoney(rs.realizarOperacionalDiversos, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalOperacionalDiversos < 0 ? 'red' : 'blue'}}>
                        {rs.totalOperacionalDiversos != null ? fmtMoney(rs.totalOperacionalDiversos, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                    <th style={{ textAlign: 'right', color: rs.orcadoOperacionalDiversos < 0 ? 'red' : 'blue'}}>
                        {rs.orcadoOperacionalDiversos != null ? fmtMoney(rs.orcadoOperacionalDiversos, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.alcanceOperacionalDiversos < 0 ? 'red' : 'blue'}}>
                        {rs.alcanceOperacionalDiversos != null ? fmtMoney(rs.alcanceOperacionalDiversos, 0).toString().replaceAll('R$', '') : 0}%
                    </th>


                </tr>
            
            ))


            setResultadoEspeciais(result.data.map((rs) => 
                

                <tr style={{ fontSize: '11px', backgroundColor: '#dae6e8'}}>
                    <th colspan="2" style={{ textAlign: 'center'}}>
                        Total Especiais
                    </th>
                    <th style={{ textAlign: 'right', color: rs.realizadoEspeciais < 0 ? 'red' : 'blue' }}>
                        {rs.realizadoEspeciais != null ? fmtMoney(rs.realizadoEspeciais, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.realizarEspeciais < 0 ? 'red' : 'blue'}}>
                        {rs.realizarEspeciais != null ? fmtMoney(rs.realizarEspeciais, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalEspeciais < 0 ? 'red' : 'blue'}}>
                        {rs.totalEspeciais != null ? fmtMoney(rs.totalEspeciais, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                    <th style={{ textAlign: 'right', color: rs.orcadoEspeciais < 0 ? 'red' : 'blue'}}>
                        {rs.orcadoEspeciais != null ? fmtMoney(rs.orcadoEspeciais, 0).toString().replaceAll('R$', '') : 0}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.alcanceEspeciais < 0 ? 'red' : 'blue'}}>
                        {rs.alcanceEspeciais != null ? fmtMoney(rs.alcanceEspeciais, 0).toString().replaceAll('R$', '') : 0}%
                    </th>


                </tr>
            
            ))



        }).catch((err) => {

            console.log(err.response)

        })



    }


    const carregaDespesas = async () => {

        /*
        if (parseInt(mesFim) < parseInt(mesInicio)) {

            //window.toastr.error('Mês término não pode ser menor que o Mês início!')
            return false

        } 
        */

        if (mesInicio && ano) {

            var dataPost = {

                ano: ano,
                mes: mesInicio,
                mesFim: mesFim,
                tipo: 'DESPESAS',
                tipoDemonstrativo: tipoDemonstrativo,
                dataInicio: moment(startDate).format('YYYY-MM-DD'),
                dataFim: moment(endDate).format('YYYY-MM-DD'),
    
            }
    
            await api.post('demonstrativoFinanceiro', dataPost).then((result) => {
    
                //console.log(result.data, '!!')

                setResultadoTotalDespesas(result.data[1].map((rs) =>
                
                    <tr style={{ fontSize: '11px', textDecoration: 'bold', backgroundColor: '#EFEFEF'}}>
                        <th colspan="2" style={{ textAlign: 'center'}}>
                            Total
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalRealizado != null ? fmtMoney(rs.totalRealizado, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalRealizar != null ? fmtMoney(rs.totalRealizar, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalTotal != null ? fmtMoney(rs.totalTotal, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                        <th style={{ textAlign: 'right'}}>
                            
                            {rs.totalOrcado != null ? fmtMoney(rs.totalOrcado, 0).toString().replaceAll('R$', '') : 0}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {rs.totalAlcance != null ? fmtMoney(rs.totalAlcance, 0).toString().replaceAll('R$', '') : 0}%
                        </th>


                    </tr>
                                
                ))
    
                setResultadoDespesas(result.data[0].map((rs) =>
                
                    <tr style={{  fontWeight: rs.fluxoCaixa == 'Total' ? 'bold' : '', fontSize: '11px', backgroundColor: rs.fluxoCaixa == 'Total' ? '#efefef' : '#faefed' }}>
    
                        <td style={{ textAlign: 'center'}}>
                            {rs.classe}
                        </td>
                        <td>
                            {rs.fluxoCaixa}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.realizado != null ? fmtMoney(rs.realizado, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.realizar != null ? fmtMoney(rs.realizar, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.total != null ? fmtMoney(rs.total, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.orcado != null ? fmtMoney(rs.orcado, 0).toString().replaceAll('R$', '') : 0}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.alcance != null ? fmtMoney(rs.alcance, 0).toString().replaceAll('R$', '') : 0}%
                        </td>
    
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        }

        

    }


    async function geraPDF() {

        //console.log(`demonstrativoFinanceiroMensalPDF/${ano}/${mesInicio}/${mesFim}/${tipoDemonstrativo}`)

        var dataPost = {

            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),

        }

        await api.post('demonstrativoFinanceiroMensalPDF', dataPost).then((result) => {

            //console.log(result.data)

            if (result.data) {

                //window.open(`http://172.16.1.253:3378/pdf/${result.data.arquivo}`)
                //window.open(`https://apiportaltransp.fajsaude.com.br/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
                //const linkAPI = process.env.REACT_APP_API

            }

            
        }).catch((err) => {

            console.log(err.response)

        })

    }



    const atualiza = () => {

        // valida datas

        //setando data1
        var data1 = moment(startDate,'YYYY-MM-DD');
        //setando data2
        var data2 = moment(endDate,'YYYY-MM-DD');
        //tirando a diferenca da data2 - data1 em dias
        var diff  = data2.diff(data1, 'days');

        if (diff < 0) {

            window.toastr.error('Data início não pode ser maior que a data fim!')
            return false

        }

        carregaReceitas()
        carregaDespesas()     
        carregaTotais()   
        
        //window.toastr.success('Atualizado com sucesso!')

    }

    useEffect(() => {

        carregaAnos()
        carregaMeses()
        consultaDataUltimoMovimento()
        carregaReceitas()
        carregaDespesas()     
        carregaTotais()   

    }, [])


    /*
    useEffect(() => {

        carregaReceitas()
        carregaDespesas()     
        carregaTotais()   
 
    }, [mesInicio, mesFim, ano, tipoDemonstrativo])
    */

    async function exportaExcelRealizado() {


        //return false
  
        window.toastr.info('Aguarde, gerando XLSX...')
  
        var dataPost = {
  
          ano: ano.toString(),
          mes: mesInicio.toString(),
          mesFim: mesFim.toString(),
          dataInicio: moment(startDate).format('YYYY-MM-DD'),
          dataFim: moment(endDate).format('YYYY-MM-DD'),
          tipoDemonstrativo: tipoDemonstrativo,
          //fluxoSemana: fluxoSemana.toString(),
          
  
      }

      //console.log(dataPost)
      //return false
  
        await api.post('movimentoXLSX', dataPost).then((result) => {

            //console.log(result.data)
  
            if (result.data.status == 'ok') {
  
                sleep(5000).then(() => {

                    //console.log(result.data)
  
                    //console.log(result.data[0].fileNameCompleto, '!!')
                    //window.location.href = result.data.arquivo
                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                })
                
  
            }
  
        }).catch((err) => {
  
            console.log(err.response)
  
        })
  
  
      }

    return (

        <div>

                <button type="button" class="btn btn-inline btn-danger" onClick={() => geraPDF()}>PDF</button>
                
                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcelRealizado()}>Exportar XLSX Realizado</button>
                        
                <br/><br/>
    
                <div className="row">
                    <div className="col-md-2 col-sm-2" style={{ display: 'none' }}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError">Ano</label>
                            <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                {listaAnos}
                            </select>
                            
                        </fieldset>
                    </div>
                    
                    

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Inicial</label>
                            <DatePicker   selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Final</label>
                            <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA"
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>


                    


                    <div className="col-md-2 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Mês Inicial</label>
                            <select type="text" className="form-control" value={mesInicio} onChange={event => setMesInicio(event.target.value)}>
                                {listaMeses}
                            </select>
                        </fieldset>
                    </div>
                    <div className="col-md-2 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Mês Final</label>
                            <select type="text" className="form-control" value={mesFim} onChange={event => setMesFim(event.target.value)}>
                                {listaMeses}
                            </select>
                        </fieldset>
                    </div>


                    <div className="col-md-2 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Mês Inicial</label>
                            <select type="text" className="form-control" value={mesInicio} onChange={event => setMesInicio(event.target.value)}>
                                {listaMeses}
                            </select>
                        </fieldset>
                    </div>
                    <div className="col-md-2 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Mês Final</label>
                            <select type="text" className="form-control" value={mesFim} onChange={event => setMesFim(event.target.value)}>
                                {listaMeses}
                            </select>
                        </fieldset>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Tipo de demonstrativo</label>
                            <select type="text" className="form-control" value={tipoDemonstrativo} onChange={event => setTipoDemonstrativo(event.target.value)}>
                                <option value="T">Total</option>
                                <option value="O">Operacionais</option>
                                <option value="E">Especiais</option>
                            </select>
                        </fieldset>
                    </div>
                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Último movimento</label>
                            <input type="text" className="form-control" value={dataUltimoMovimento} disabled style={{ backgroundColor: '#f7e1c1'}} />
                                
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">&nbsp;</label>
                            
                            <button type="button" class="btn btn-inline btn-info" onClick={() => atualiza()}>Consultar</button>

                        </fieldset>
                    </div>

                </div>

                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    
                    <tbody>
                        <tr>
                            <th style={{ backgroundColor: '#afd8e0'}} colspan="5"><center>Receitas</center></th>
                            <th style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></th>
                            <th style={{ backgroundColor: '#afd8e0'}} colspan="2"><center>&nbsp;</center></th>
                        </tr>
                        <tr style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            <th>Tipo</th>
                            <th>Fluxo de caixa</th>
                            <th>Recebido</th>
                            <th>A Receber</th>
                            <th>Total</th>
                            <th style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></th>
                            <th>Orçado</th>
                            <th>Alcance</th>
                            
                            
                        </tr>
                        {resultadoReceitas}
                        {resultadoTotalReceitas}


                    <tr>
                        <td colspan="8">&nbsp;</td>
                    </tr>
                       
                        <tr>
                            <th style={{ backgroundColor: '#e0baaf'}} colspan="5"><center>Despesas</center></th>
                            <th style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></th>
                            <th style={{ backgroundColor: '#e0baaf'}} colspan="2"><center>&nbsp;</center></th>
                        </tr>

                        <tr style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                            <th>Tipo</th>
                            <th>Fluxo de caixa</th>
                            <th>Pago</th>
                            <th>A Pagar</th>
                            <th>Total</th>
                            <th style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></th>
                            <th>Orçado</th>
                            <th>Alcance</th>
                            
                            
                        </tr>
                        
                        {resultadoDespesas}
                        {resultadoTotalDespesas}

                        <tr>
                            <th style={{ backgroundColor: '#c7d3d4'}} colspan="5"><center>Resultado Financeiro</center></th>
                            <th style={{ backgroundColor: '#FFF', borderBlockColor: '#FFF'}}></th>
                            <th style={{ backgroundColor: '#c7d3d4'}} colspan="2"><center>&nbsp;</center></th>
                        </tr>
                        {resultadoTotalGeral}
                        
                        
                    </tbody>
                </table>



        </div>

    )

}

export default Demonstrativo