import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout'

import Login from './pages/Login'
import Home from './pages/Home'
import Financeiro from './pages/Financeiro'

import CentroCusto from './pages/CentroCusto'

import Usuarios from './pages/Usuarios'
import Usuario from './pages/Usuario'
import Importacoes from './pages/Importacoes'
import Compras from './pages/Compras'
import Compra from './pages/Compra'
import Consumo from './pages/Consumo'
import Orcamento from './pages/Orcamento'
import Contratos from './pages/Contratos'
import FluxoCaixa from './pages/FluxoCaixa'
import Calendario from './pages/Calendario'
import ComprasPedido from './pages/ComprasPedido'
import FinanceiroAdm from './pages/FinanceiroAdm'
import MateriaPrima from './pages/MateriaPrima'

import Fornecedores from './pages/Fornecedores'
import Fornecedor from './pages/Fornecedor'

import Opmes from './pages/Opmes'
import Opme from './pages/Opme'

import Downloads from './pages/Downloads'
import DownloadsAdm from './pages/DownloadsAdm'

import { ProtectedRoute } from "./components/protected.route";


import AtualizaContext from './context/atualizaContext'
import TotalVinculoPendenteContext from './context/totalVinculoPendenteContext'
import TotalMovimentoPendenteContext from './context/totalMovimentoPendenteContext'

import ScrollIntoView from './components/ScrollIntoView';
import ScrollToTop from "./components/scrollToTop";


const Rotas = () => {


    return (

        <BrowserRouter>

                <ScrollToTop />

                <AtualizaContext>
                <TotalVinculoPendenteContext>
                <TotalMovimentoPendenteContext>
                
                    <Routes>
                    

                        <Route element={<Login />} path='/' />

                        <Route element={<Layout />}>
                            
                            <Route path="/Inicio" element={<ProtectedRoute><Home /></ProtectedRoute>} />                          

                            <Route element={<ProtectedRoute><Financeiro /></ProtectedRoute>} path='/Financeiro' />
                            
                            <Route element={<ProtectedRoute><Compras /></ProtectedRoute>} path='/Compras' />

                            <Route element={<ProtectedRoute><Compra /></ProtectedRoute>} path='/Compra' />
                            <Route element={<ProtectedRoute><Compra /></ProtectedRoute>} path='/Compra/:slug' />

                            <Route element={<ProtectedRoute><Calendario /></ProtectedRoute>} path='/Calendario' />
                            <Route element={<ProtectedRoute><ComprasPedido /></ProtectedRoute>} path='/Compras/Solicitacoes' />
                            

                            <Route element={<ProtectedRoute><CentroCusto /></ProtectedRoute>} path='/CentroCusto' />                        
                            <Route element={<ProtectedRoute><Consumo /></ProtectedRoute>} path='/Planejamento' />
                            <Route element={<ProtectedRoute><Orcamento /></ProtectedRoute>} path='/Orcamento' />
                            <Route element={<ProtectedRoute><Contratos /></ProtectedRoute>} path='/Contratos' />

                            <Route element={<ProtectedRoute><Usuarios /></ProtectedRoute>} path='/Usuarios' />
                            <Route element={<ProtectedRoute><Usuario /></ProtectedRoute>} path='/Usuario/:slug' />
                            <Route element={<ProtectedRoute><Usuario /></ProtectedRoute>} path='/Usuario' />
                            
                            <Route element={<ProtectedRoute><FluxoCaixa /></ProtectedRoute>} path='/FluxoCaixa' />

                            <Route element={<ProtectedRoute><Importacoes /></ProtectedRoute>} path='/Importacoes' />
                            
                            <Route element={<ProtectedRoute><Opmes /></ProtectedRoute>} path='/OPMEs' />
                            <Route element={<ProtectedRoute><Opme /></ProtectedRoute>} path='/OPME' />
                            <Route element={<ProtectedRoute><Opme /></ProtectedRoute>} path='/OPME/:slug' />

                            <Route element={<ProtectedRoute><MateriaPrima /></ProtectedRoute>} path='/MateriaPrima' />
                            
                            <Route element={<ProtectedRoute><Fornecedores /></ProtectedRoute>} path='/Fornecedores' />
                            <Route element={<ProtectedRoute><Fornecedor /></ProtectedRoute>} path='/Fornecedor' />
                            <Route element={<ProtectedRoute><Fornecedor /></ProtectedRoute>} path='/Fornecedor/:slug' />

                            <Route element={<ProtectedRoute><FinanceiroAdm /></ProtectedRoute>} path='/Financeiro/Administracao' />

                            <Route element={<ProtectedRoute><Downloads /></ProtectedRoute>} path='/Downloads' />
                            <Route element={<ProtectedRoute><DownloadsAdm /></ProtectedRoute>} path='/Downloads/Administracao' />


                            
                        
                        </Route>
                        

                    </Routes>
                </TotalMovimentoPendenteContext>
                </TotalVinculoPendenteContext>            
                </AtualizaContext>

            

        </BrowserRouter>

    )

}


export default Rotas