import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import { validaCNPJ } from '../functions/generalFunctions'



const FornecedorCadastro = (props) => {

    const navigate = useNavigate()
    const slug = props.slug

    const [fornecedor_id, setFornecedor_id] = useState()
    const [cnpj, setCnpj] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')

    const salvar = () => {

        var dataPost = {

            cnpjCpf: cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            razaoSocial: razaoSocial,

        }

        if (!validaCNPJ(cnpj)) {

            window.toastr.error('<b>CNPJ</b> inválido!')
            return false

        }

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma os dados?',
		    buttons: {
		        Sim: function () {



                    if (fornecedor_id) {


                        api.put(`fornecedor/${fornecedor_id}`, dataPost).then((result) => {

                            console.log(result.data)
                            window.toastr.success('Registro salvo com sucesso!')
                            

                        }).catch((err) => {

                            console.log(err.response)

                        })

                    } else {

                        

                        api.post('fornecedor', dataPost).then((result) => {

                            console.log(result.data)
                            setFornecedor_id(result.data[0].fornecedor_id)
                            window.toastr.success('Registro salvo com sucesso!')
                            

                        }).catch((err) => {

                            console.log(err.response)

                        })

                    }


                },
                Cancelar: function() {


                },
            }
        })


    }

    const apagar = () => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {



                    api.delete(`fornecedor/${fornecedor_id}`, dataPost).then((result) => {

                        console.log(result.data)
                        window.toastr.success('Registro apagado com sucesso!')
                        navigate('/Fornecedores')
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })


                },
                Cancelar: function() {


                },
            }
        })


    }


    const carregaDados = (fornecedor_id) => {

        api.get(`fornecedorListaUm/${fornecedor_id}`).then((result) => {

            var data = result.data[0]

            setCnpj(data.cnpjCpf)
            setRazaoSocial(data.razaoSocial)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        setFornecedor_id(slug)
        carregaDados(slug)

    }, [slug])

    return (

        <div>

            <div className="row">
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError2">CNPJ *</label>
                        <input type="text" className="form-control" value={cnpj} onChange={event => setCnpj(event.target.value)} 
                        
                        
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        
                        
                        
                        />
                    </fieldset>
                </div>
            
                <div className="col-md-9">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Razão Social *</label>
                        <input type="text" className="form-control" value={razaoSocial} onChange={event => setRazaoSocial(event.target.value)} style={{ textTransform: 'uppercase'}}  />
                            
                        
                    </fieldset>
                </div>

                <div className="col-md-12">

                    

                    <button type="button" class="btn btn-inline btn-warning" onClick={() => navigate('/Fornecedores')}>Voltar</button>
                    <button type="button" class="btn btn-inline btn-danger" style={{ display: fornecedor_id ? 'table-row' : 'none'}} onClick={() => apagar()}>Apagar</button>
                    <button type="button" class="btn btn-inline btn-primary" onClick={() => salvar()}>Salvar</button>
                    

                </div>

               

            </div>




        </div>

    )

}


export default FornecedorCadastro