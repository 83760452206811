import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import api from "../components/api"
import { validaCNPJ, validaCPF } from "../functions/generalFunctions"
import ModalPreviaCertidoes from '../modal/ModalPreviaCertidoes'
import ModalCertidaoAnexo from '../modal/ModalCertidaoAnexo'


const DemandaCertidoes = (props) => {


    const { demanda_id, fornecedor_id_props,  cnpjCpf_props, razaoSocial_props, fornecedorVinculado_props } = props

    const usuario_id = sessionStorage.getItem('usuario_id')

    const [fornecedor, setFornecedor] = useState('')
    const [resultadoBusca, setResultadoBusca] = useState('')
    const [resultadoCertidoes, setResultadoCertidoes] = useState([])

    const [fornecedor_id, setFornecedor_id] = useState()
    const [razaoSocial, setRazaoSocial] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [habCampos, setHabCampos] = useState(false)

    const [corCampo, setCorCampo] = useState('#FFF')


    const [certidao_id, setCertidao_id] = useState('')
    const [validade, setValidade] = useState()

    const [descricaoAnexo, setDescricaoAnexo] = useState('')

    const [demandaCertidao_id, setDemandaCertidao_id] = useState()
    const [habAnexos, setHabAnexos] = useState(false)

    const [fornecedorVinculado, setFornecedorVinculado] = useState(false)


    useEffect(() => {

        setFornecedor_id(fornecedor_id_props)

    }, [fornecedor_id_props])

    useEffect(() => {

        setCNPJ(cnpjCpf_props)

    }, [cnpjCpf_props])

    useEffect(() => {

        setRazaoSocial(razaoSocial_props)

    }, [razaoSocial_props])


    useEffect(() => {

        setFornecedorVinculado(fornecedorVinculado_props)

    }, [fornecedorVinculado_props])


    const download = (arquivo) => {


        if (arquivo) {

            window.open(`${process.env.REACT_APP_API}/upload/certidoes/${arquivo}`)
            
        }
        
        
    }

    const novoFornecedor = () => {

        setFornecedor_id()
        setCNPJ('')
        setRazaoSocial('')
        setResultadoBusca('')
        setHabCampos(true)
        setHabAnexos(false)
        setFornecedorVinculado(false)
        setFornecedor('')

    }

    const salvaFornecedor = () => {

        

        var dataPost = {

            cnpjCpf: cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            razaoSocial: razaoSocial,

        }

        if (!validaCNPJ(cnpj)) {

            window.toastr.error('<b>CNPJ</b> inválido!')
            return false

        }

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma os dados?',
		    buttons: {
		        Sim: function () {


                    api.post('fornecedor', dataPost).then((result) => {

                        //console.log(result.data)
                        setFornecedor_id(result.data[0].fornecedor_id)
                        window.toastr.success('Registro salvo com sucesso!')
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })

                


                },
                Cancelar: function() {


                },
            }
        })

    }


    useEffect(() => {

        if (fornecedor != '') {

            var dataPost = {

                fornecedor: fornecedor,

            }

            api.post('buscaFornecedor', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoBusca(result.data.map((rs) => 
                
                        
                    <Link onClick={() => {setHabAnexos(false);setCorCampo('orange');setHabCampos(false);setFornecedor(`${rs.cnpjCpf} - ${rs.razaoSocial}`);setFornecedor_id(rs.fornecedor_id);setCNPJ(rs.cnpjCpf);setRazaoSocial(rs.razaoSocial)}}><font color='purple'>[{rs.cnpjCpf}]</font> - {rs.razaoSocial}&nbsp;</Link>
                        
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }



    }, [fornecedor])




    const carregaCertidoes = () => {

        

        api.get(`demandaCertidoes/${demanda_id}`).then((result) => {

            //console.log(result.data)
            setResultadoCertidoes(result.data.map((rs) =>
            
                <tr style={{ fontSize: '11px'}}>
                    <td style={{ cursor: rs.arquivo ? 'pointer' : 'auto'}} onClick={() => download(rs.arquivo)}>
                        <font color={ rs.arquivo ? 'blue' : ''}>{rs.descricao}</font>
                    </td>
                    <td style={{ textAlign: 'center'}}>
                        { rs.validade == false ? 'N/A' : rs.dataValidade}
                    </td>
                    <td style={{ textAlign: 'center', cursor: 'pointer', backgroundColor: rs.status == 'Anexado' ? '#ceedd8' : '#f0e7e6' }} onClick={() => {setDemandaCertidao_id(rs.demandaCertidao_id);setDescricaoAnexo(rs.descricao);setValidade(rs.validade);setCertidao_id(rs.certidao_id);window.$('#DemandaCertidaoAnexo').modal('show')} } >
                        {rs.status}
                    </td>
                </tr>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const salvaVinculaFornecedor = () => {

        
        setCorCampo('#FFF')
        setFornecedor('')
        

        api.put(`vinculaFornecedor/${demanda_id}/${fornecedor_id}/${usuario_id}`).then((result) => {

            console.log(result.data)
            setFornecedorVinculado(true)
            carregaCertidoes()
            window.toastr.success('Registro salvo com sucesso!')

        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

        if (demanda_id && fornecedor_id) {

            //setHabCampos(true)
            //setHabAnexos(true)
            carregaCertidoes()

        }       

    }, [demanda_id, fornecedor_id])


    useEffect(() => {

        setResultadoBusca('')
        
    }, [fornecedor_id])



    return (

        <div>


            <div className="row">
                <div className="col-md-12">
                    <fieldset className="form-group">
                        <label className="form-label">Fornecedor</label>
                        <textarea className="form-control" style={{ backgroundColor: corCampo}} value={fornecedor} onChange={event => setFornecedor(event.target.value)} placeholder="Busque pelo fornecedor..." onFocus={event => {setFornecedor('');setFornecedor_id();setCorCampo('#FFF')}}/>
                        {resultadoBusca}
                    </fieldset>
                </div>
                <div className="col-md-12">
                    
                    <button type="button" className="btn btn-inline btn-info-outline" onClick={() => novoFornecedor()}>Novo fornecedor</button>
                    <button type="button" className="btn btn-inline btn-warning-outline" style={{ display: fornecedor_id ? 'table-row' : 'none'}} onClick={() => salvaVinculaFornecedor()}>Vincula fornecedor</button>
                    <br/><br/>
                </div>

                


                <div className="col-md-3" style={{ display: habCampos == true || fornecedorVinculado == true ? 'table-row' : 'none'}}>
                    <fieldset className="form-group">
                        <label className="form-label">CNPJ/CPF</label>
                        <input type="text" className="form-control" value={cnpj} onChange={event => setCNPJ(event.target.value)} disabled={ fornecedor_id ? true : false } 
                        
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        
                        />
                    </fieldset>
                </div>



                <div className="col-md-9" style={{ display: habCampos == true || fornecedorVinculado ==  true ? 'table-row' : 'none'}}>
                    <fieldset className="form-group">
                        <label className="form-label">Razão Social</label>
                        <input type="text" className="form-control" value={razaoSocial} onChange={event => setRazaoSocial(event.target.value)} disabled={ fornecedor_id ? true : false }  />
                    </fieldset>
                </div>

                <div className="col-md-12" style={{ display: habCampos == true && !fornecedor_id ? 'table-row' : 'none'}}>
                    
                    <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvaFornecedor()}>+ Salva Fornecedor</button>
                    <br/><br/>
                </div>
                

                <div className="col-md-12" style={{ display: fornecedorVinculado == true ? 'table-row' : 'none'}}>
                    <fieldset className="form-group">
                        <label className="form-label">Certidões</label>
                        

                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                            <thead>
                                    <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                        
                                        <th>
                                            
                                        Descrição                                 

                                        </th>
                                        <th>
                                            
                                        Validade

                                        </th>
                                            
                                        <th>
                                            
                                        Status

                                        </th>
                                        
                                    
                                    </tr>
                                    
                            </thead>
                            <tbody>

                                {resultadoCertidoes}

                            </tbody>      
                            <tr>

                                <td colspan="3" style={{ textAlign: 'right', display: 'none'}}>

                                <button type="button" className="btn btn-inline btn-primary-outline" onClick={() => window.$('#ModalPreviaCertidoes').modal('show')}>Verifica certidões</button>

                                </td>
                            
                            </tr>                 
                            
                            
                        </table>

                    </fieldset>
                </div>


            </div>



            <ModalPreviaCertidoes fornecedor_id={fornecedor_id} />
            <ModalCertidaoAnexo fornecedor_id={fornecedor_id} descricaoAnexo={descricaoAnexo} certidao_id={certidao_id} validade={validade} listaCertidoes={carregaCertidoes} demanda_id={demanda_id} />



        </div>

    )

}


export default DemandaCertidoes