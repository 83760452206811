import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import { fmtMoney } from '../functions/generalFunctions'
import api from '../components/api'

const ModalReservaRecursos = (props) => {


    const perfil = sessionStorage.getItem('perfil')


    const pedidoCompra_id = props.pedidoCompra_id

    const [valorAutorizado, setValorAutorizado] = useState()

    const [resultadoNatureza, setResultadoNatureza] = useState()    
    
    const [codigoNatureza, setCodigoNatureza] = useState()
    const [natureza, setNatureza] = useState('')
    const [resultadoItemContabil, setResultadoItemContabil] = useState()
    const [itemContabil, setItemContabil] = useState('')
    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [resultadoArea, setResultadoArea] = useState()
    const [area, setArea] = useState('')
    const [resultadoSubItem, setResultadoSubItem] = useState()
    const [subItem, setSubItem] = useState('')
    
    const [assinatura, setAssinatura] = useState('')

    const [chaveAssinaturaReservaRecurso, setChaveAssinaturaReservaRecurso] = useState('')
    const [cpfAssinaturaReservaRecursos, setCpfAssinaturaReservaRecursos] = useState('')
    const [assinadoReservaRecurso, setAssinadoReservaRecurso] = useState(false)
    const [dataAssinaturaReservaRecurso, setDataAssinaturaReservaRecurso] = useState('')
    const [nomeAssinaturaReservaRecurso, setNomeAssinaturaReservaRecurso] = useState('')

    


    const assinarReservaRecurso = () => {

        var nome = sessionStorage.getItem('nome')
        var cpf = sessionStorage.getItem('cpf')
       
        var dataPost = {

            nome: sessionStorage.getItem('nome'),
            cpf: sessionStorage.getItem('cpf'),

        }



        if (valorAutorizado == '' || valorAutorizado == null) {

            window.toastr.error('<b>Valor autorizado</b> não pode ficar em branco!')
            return false;

        }

        

        if (codigoNatureza == '' || codigoNatureza == null) {

            window.toastr.error('<b>Natureza</b> não pode ficar em branco!')
            return false;

        }


        if (itemContabil == '' || itemContabil == null) {

            window.toastr.error('<b>Item Contábil</b> não pode ficar em branco!')
            return false;

        }


        if (area == '' || area == null) {

            window.toastr.error('<b>Área</b> não pode ficar em branco!')
            return false;

        }


        if (subItem == '' || subItem == null) {

            window.toastr.error('<b>Sub-item</b> não pode ficar em branco!')
            return false;

        }

        

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirma assinar este documento?',
		    content: `${cpf} - ${nome}`,
		    buttons: {
		        Sim: function () {


                                    
                        api.put(`assinaReservaRecurso/${pedidoCompra_id}/0`, dataPost).then((result) => {

                            

                            var data = result.data

                            setChaveAssinaturaReservaRecurso(data.chaveAssinaturaReservaRecurso)
                            setCpfAssinaturaReservaRecursos(data.cpfAssinaturaReservaRecurso)
                            setAssinadoReservaRecurso(data.assinadoReservaRecurso)
                            setDataAssinaturaReservaRecurso(data.dataAssinaturaReservaRecurso)
                            setNomeAssinaturaReservaRecurso(data.nomeAssinaturaReservaRecurso)

                            if (data.assinadoReservaRecurso) {

                                setAssinatura(`Em ${data.dataAssinaturaReservaRecurso} por ${data.cpfAssinaturaReservaRecurso} - ${data.nomeAssinaturaReservaRecurso} \nChave ${data.chaveAssinaturaReservaRecurso}`)
                
                            }

                            /*
                            carregaPedido()
                            carregaPedidoHistorico()
                            props.carregaPedidos()

                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'green',
                                title: `Autorização`,
                                content: `${ autorizado ? 'Solicitação com autorização desfeita' : 'Pedido autorizado' }`,
                                buttons: {
                                    OK: function () {

                                        pdfPedido(result.data.pedidoCompra_id)

                                    }
                                },
                    
                            });
                            */

                            props.carregaReservaRecursos()

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    
    const salvaAssinatura = () => {

        if (valorAutorizado == '' || valorAutorizado == null) {

            window.toastr.error('<b>Valor autorizado</b> não pode ficar em branco!')
            return false;

        }


        var dataPost = {

            pedidoCompra_id: pedidoCompra_id,            
            valorAutorizado: valorAutorizado ? valorAutorizado.replaceAll('.', '').replaceAll(',','.') : 0,
            
        }

        console.log(dataPost)


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
            title: 'Salvar',
            content: 'Confirma salvar os dados da assinatura?',
            buttons: {
                Ok: function () {

                    api.put('pedidoCompraAssinatura', dataPost).then((result) => {

                        //console.log(result.data)
                        window.toastr.success('Salvo com sucesso!')


                    }).catch((err) => {

                        console.log(err)

                    })

                },
                Cancelar: function () {


                },
            },

        });


    }




    

    const salvaReservaRecursos = () => {


        if (codigoNatureza == '' || codigoNatureza == null) {

            window.toastr.error('<b>Natureza</b> não pode ficar em branco!')
            return false;

        }


        if (itemContabil == '' || itemContabil == null) {

            window.toastr.error('<b>Item Contábil</b> não pode ficar em branco!')
            return false;

        }


        if (area == '' || area == null) {

            window.toastr.error('<b>Área</b> não pode ficar em branco!')
            return false;

        }


        if (subItem == '' || subItem == null) {

            window.toastr.error('<b>Sub-item</b> não pode ficar em branco!')
            return false;

        }



        var dataPost = {

            pedidoCompra_id: pedidoCompra_id,
            subItem: subItem,
            area: area,
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            natureza: natureza,
            itemContabilDescri: itemContabilDescri,

        }

        console.log(dataPost)


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
            title: 'Salvar',
            content: 'Confirma salvar os dados da Reserva de Recurso?',
            buttons: {
                Ok: function () {

                    api.put('pedidoCompraReservaRecursos', dataPost).then((result) => {

                        //console.log(result.data)
                        window.toastr.success('Salvo com sucesso!')


                    }).catch((err) => {

                        console.log(err)

                    })

                },
                Cancelar: function () {


                },
            },

        });

        


    }



    const carregaNaturezas = () => {


        var dataPost = {

            tipo: "DESPESAS",

        }

        api.post('listaNaturezaFiltro', dataPost).then((result) => {

            
            
            setResultadoNatureza(result.data.map((rs) =>
            
                <option value={rs.codigoNatureza}>{rs.natureza}</option>
            
            ))

        }).catch((err) => {

            console.log(err)

        })

    }


    const carregaItemContabil = () => {

        var dataPost = {

            codigoNatureza: codigoNatureza,
            tipo: "DESPESAS",

        }

        console.log(dataPost)

        setResultadoItemContabil()

        api.post('listaItemContabilFiltro', dataPost).then((result) => {

            
            setResultadoItemContabil(result.data.map((rs) =>
            
                <option value={rs.itemContabil}>{rs.itemContabilDescri}</option>
            
            ))

        }).catch((err) => {

            console.log(err)

        })

    }


    const carregaAreas = () => {

        var dataPost = {

            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            tipo: 'DESPESAS',

        }

        console.log(dataPost)
        
        setResultadoArea()

        api.post('listaAreaFiltro', dataPost).then((result) => {

            
            setResultadoArea(result.data.map((rs) =>
            
                <option value={rs.area}>{rs.area}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    
    const carregaSubItem = () => {

        var dataPost = {

            area: area,
            tipo: 'DESPESAS',
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,

        }

        setResultadoSubItem()

        api.post('listaSubItemFiltro', dataPost).then((result) => {

            setResultadoSubItem(result.data.map((rs) =>
            
                <option value={rs.fluxoCaixa}>{rs.fluxoCaixa}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }




    const pdfReservaRecursos = () => {


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
            title: 'Aviso',
            content: 'Antes de gerar o PDF certifique-se de que as informações foram salvas!',
            buttons: {
                Ok: function () {

                    
                    reservaRecursosPDF()


                },
                Cancelar: function () {


                },
            },

        });

    }



    const reservaRecursosPDF = () => {


        api.get(`reservaRecursosPDF/${pedidoCompra_id}`).then((result) => {

            //console.log(result.data)
            window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
            //window.$('#modalDespacho').modal('hide')

        }).catch((err) => {

            console.log(err.response)

        })



    }




    const carregaReservaRecurso = () => {


        api.get(`reservaRecursos/${pedidoCompra_id}`).then((result) => {

            console.log(result.data)

            var data = result.data[0]

            setChaveAssinaturaReservaRecurso(data.chaveAssinaturaReservaRecurso)
            setCpfAssinaturaReservaRecursos(data.cpfAssinaturaReservaRecurso)
            setAssinadoReservaRecurso(data.assinadoReservaRecurso)
            setDataAssinaturaReservaRecurso(data.dataAssinaturaReservaRecurso)
            setNomeAssinaturaReservaRecurso(data.nomeAssinaturaReservaRecurso)


            setCodigoNatureza(data.codigoNatureza)
            setItemContabil(data.itemContabil)
            setArea(data.area)
            setSubItem(data.subItem)
            setValorAutorizado(data.valorAutorizado ? fmtMoney(data.valorAutorizado, 2).replaceAll('R$', '') : '')



            if (data.assinadoReservaRecurso) {

                setAssinatura(`Em ${data.dataAssinaturaReservaRecurso} por ${data.cpfAssinaturaReservaRecurso} - ${data.nomeAssinaturaReservaRecurso} \nChave ${data.chaveAssinaturaReservaRecurso}`)

            }

        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {


        carregaNaturezas()     
        carregaReservaRecurso()
        

    }, [pedidoCompra_id])



    useEffect(() => {

        if (codigoNatureza) {

            carregaItemContabil()

        }

    }, [codigoNatureza])


    useEffect(() => {

        if (itemContabil) {

            carregaAreas()

        }

    }, [itemContabil])



    useEffect(() => {

        if (area) {

            carregaSubItem()

        }

    }, [area])



    return (

        <div>

            

                <div className="modal fade modal-ReservaRecurso"
                    id="modalReservaRecurso"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Reserva de Recursos</h4>
                        </div>
                        <div class="modal-body">

                            <div className="row">                        
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Valor autorizado R$</label>
                                        <CurrencyInput type="text" placeholder="0,00" className="form-control" value={valorAutorizado} onChange={event => setValorAutorizado(event.target.value)} maxLength={150} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } />
                                    
                                    </fieldset>
                                </div>

                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Natureza</label>
                                        <select className="form-control" value={codigoNatureza} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } onChange={event => {setCodigoNatureza(event.target.value);setNatureza(event.target[event.target.selectedIndex].text)}}>
                                            <option value="">[Selecione]</option>
                                            {resultadoNatureza}
                                        </select>
                                    
                                    </fieldset>
                                </div>
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Item Contábil</label>
                                        <select className="form-control" value={itemContabil} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } onChange={event => {setItemContabil(event.target.value);setItemContabilDescri(event.target[event.target.selectedIndex].text)}}>
                                            <option value="">[Selecione]</option>
                                            {resultadoItemContabil}
                                        </select>
                                    
                                    </fieldset>
                                </div>
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Área</label>
                                        <select className="form-control" value={area} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } onChange={event => setArea(event.target.value)}>
                                            <option value="">[Selecione]</option>
                                            {resultadoArea}
                                        </select>
                                    
                                    </fieldset>
                                </div>

                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Sub-item</label>
                                        <select className="form-control" value={subItem} onChange={event => setSubItem(event.target.value)} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } >
                                            <option value="">[Selecione]</option>
                                            {resultadoSubItem}
                                        </select>
                                    
                                    </fieldset>
                                </div>


                                <div className="col-md-12" style={{ display: assinadoReservaRecurso == true ? 'table-row' : 'none'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Assinatura</label>
                                        <textarea rows="2" className="form-control" disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } value={assinatura} style={{ backgroundColor: '#e9f2cb'}}  />
                                    </fieldset>
                                </div>



                                <div className="col-md-12">

                                    <div align="right">

                                        <button type="button" className="btn btn-inline btn-info-outline" onClick={() => assinarReservaRecurso()} style={{ display: (perfil == 'Administrador' || perfil == 'Compras') && assinadoReservaRecurso == false ? 'table-row' : assinadoReservaRecurso == true ? 'none' : 'table-row' }}  >Assinar documento</button>
                                        <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => pdfReservaRecursos()} style={{ display: (perfil == 'Administrador' || perfil == 'Compras') && assinadoReservaRecurso == true ? 'table-row' : 'none' }} >PDF Reserva de Recursos</button>
                                        <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvaReservaRecursos()} style={{ display: (perfil == 'Administrador' || perfil == 'Compras') && assinadoReservaRecurso == false ? 'table-row' : assinadoReservaRecurso == true ? 'none' : 'table-row' }}  >Salvar Reserva de Recursos</button>

                                    </div>


                                </div>
                            </div>

                            
                            

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>

                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

    )

}


export default ModalReservaRecursos