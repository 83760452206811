import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import FornecedorCadastro from '../componentsPages/FornecedorCadastro'
import FornecedorCertidoes from '../componentsPages/FornecedorCertidoes'


const Fornecedor = () => {

    const { slug } = useParams()


    return (

        <div>

            <h5 className="m-t-lg with-border">Fornecedor</h5>


            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4b-tab-1b" role="tab" data-toggle="tab">
                                Cadastro
                            </a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-4b" role="tab" data-toggle="tab">
                                Certidões
                            </a>
                        </li>
                        <li className="nav-item" style={{ display: 'none'}}>
                            <a className="nav-link" href="#tabs-4-tab-5b" role="tab" data-toggle="tab">
                                Itens vigentes
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4b-tab-1b">

                        <FornecedorCadastro  slug={slug} />

                    </div>
                   
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-4b">

                        <FornecedorCertidoes fornecedor_id={slug} />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-5b">

                        c

                    </div>
                    
                </div>
            </section>


            

        </div>

    )

}

export default Fornecedor