import { useEffect, useState } from 'react'
import api from '../components/api'
import moment from 'moment'


import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

import "../components/styles.css";

registerLocale('ptBR', ptBR)



const DemonstrativoFinanceiroDetalhado = () => {


    var dataInicio = `${moment().format('YYYY')}-01-01`

    var dataInicio = moment(dataInicio, 'YYYY-MM-DD').toDate()
    

    const [startDate, setStartDate] = useState(dataInicio);
    const [endDate, setEndDate] = useState(new Date())

    const [dataUltimoMovimento, setDataUltimoMovimento] = useState()

    const [tipoDemonstrativo, setTipoDemonstrativo] = useState('T')
    const [resultado, setResultado] = useState([])



    const consultaDataUltimoMovimento = () => {


        api.get('listaDataMovimento').then((result) => {

            //console.log(result.data)      
            setDataUltimoMovimento(result.data[0].dataMovimento)      

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const exportaExcel = () => {





    }

    const atualiza = () => {

        
        //setando data1
        var data1 = moment(startDate,'YYYY-MM-DD');
        //setando data2
        var data2 = moment(endDate,'YYYY-MM-DD');
        //tirando a diferenca da data2 - data1 em dias
        var diff  = data2.diff(data1, 'days');

        if (diff < 0) {

            window.toastr.error('Data início não pode ser maior que a data fim!')
            return false

        }


        var dataPost = {

            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),

        }

        api.post('demonstrativoFinanceiroDetalhe', dataPost).then((result) => {

            //console.log(result.data)
            setResultado(result.data.map((rs) =>
            
                <tr style={{ fontSize: '11px'}}>

                    <td>{rs.codigoNatureza}</td>
                    <td>{rs.natureza}</td>
                    <td>{rs.itemConta}</td>
                    <td>{rs.tipo}</td>
                    <td>{rs.fluxoCaixa}</td>
                    <td>{rs.area}</td>
                    <td>{rs.itemContaDescri}</td>
                    <td style={{ textAlign: 'right'}}>{rs.totalReceitas ? rs.totalReceitas.toLocaleString('pt-BR') : '0,00'}</td>
                    <td style={{ textAlign: 'right'}}>{rs.totalDespesas ? rs.totalDespesas.toLocaleString('pt-BR') : '0,00'}</td>


                </tr>
            
            ))
            

        }).catch((err) => {

            console.log(err.response)

        })

    }

    useEffect(() => {

        consultaDataUltimoMovimento()

    }, [])

    return (

        <div>

                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} disabled>Exportar XLSX </button>
                        
                <br/><br/>

                <div className="row">

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Inicial</label>
                            <DatePicker   selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Final</label>
                            <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA"
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-3 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Tipo de demonstrativo</label>
                            <select type="text" className="form-control" value={tipoDemonstrativo} onChange={event => setTipoDemonstrativo(event.target.value)}>
                                <option value="T">Total</option>
                                <option value="O">Operacionais</option>
                                <option value="E">Especiais</option>
                            </select>
                        </fieldset>
                    </div>
                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Último movimento</label>
                            <input type="text" className="form-control" value={dataUltimoMovimento} disabled style={{ backgroundColor: '#f7e1c1'}} />
                                
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">&nbsp;</label>
                            
                            <button type="button" class="btn btn-inline btn-info" onClick={() => atualiza()}>Consultar</button>

                        </fieldset>
                    </div>


                </div>


                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    
                    <thead>
                        <tr>
                            <th>Cód. Natureza</th>
                            <th>Natureza</th>
                            <th>Item Contábil</th>
                            <th>Tipo</th>
                            <th>Fluxo de Caixa</th>
                            <th>Área</th>
                            <th>Item Contábil</th>

                            <th style={{ backgroundColor: '#afd8e0'}} ><center>Receitas</center></th>
                            <th style={{ backgroundColor: '#e0baaf'}} ><center>Despesas</center></th>
                        </tr>
                    </thead>
                    <tbody>
                        {resultado}
                    </tbody>
                </table>

        </div>

    )

}


export default DemonstrativoFinanceiroDetalhado