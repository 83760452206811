import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { sleep } from '../functions/generalFunctions';
import InputMask from 'react-input-mask'
import moment from 'moment'

import ModalDemandaCompra from '../modal/ModalDemandaCompra'
import ModalCompraImporta from '../modal/ModalImportaCompras'
import ModalAlteraSituacaoCompras from '../modal/ModalAlteraSituacaoCompras'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "../components/styles.css";

import Modal from 'react-bootstrap/Modal'
import Compra from '../componentsPages/Compra'



const ComprasDemandas = () => {


  const [show, setShow] = useState(false);

    const navigate = useNavigate()

    const perfil = sessionStorage.getItem('perfil')

    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    //const [ano, setAno] = useState()
    const [listaAnos, setListaAnos] = useState()

    const [demanda_id, setDemanda_id] = useState()
    const [resultado, setResultado] = useState([])
    const [mostraResultado, setMostraResultado] = useState(false)
    
    const [comprador, setComprador] = useState('')
    const [solicitante, setSolicitante] = useState('')
    const [demandaStatus_id, setDemandaStatus_id] = useState('')

    const [resultadoCompradores, setResultadoCompradores] = useState([])
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState([])
    const [resultadoStatus, setResultadoStatus] = useState([])

    const [resultadoModalidades, setResultadoModalidades] = useState()
    const [modalidade_id, setModalidade_id] = useState()

    const [dataInicio, setDataInicio] = useState('')
    const [dataTermino, setDataTermino] = useState('')
    const [tipoPeriodo, setTipoPeriodo] = useState('')
    const [numeroProtheus, setNumeroProtheus] = useState('')
    const [processo, setProcesso] = useState('')

    const [usuario_id_comprador, setUsuarioId_comprador] = useState('')

    const [identificacao, setIdentificacao] = useState('')

    const [tipoProcesso, setTipoProcesso] = useState('')
    const [totalProcessos, setTotalProcessos] = useState(0)


    function carregaAnos() {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }


        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    const columns = useMemo(
        () => [


          {
            accessorKey: 'fase',
            header: 'Fase',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == 'Concluido' ? 'success' : renderedCellValue == 'Cancelado' ? 'danger' : '' }`}>{renderedCellValue}</span>
           
            ),
            
          },
          
           

          {
            accessorKey: 'diasUltimaPosicao',
            header: 'Dias última posição',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

          {
            accessorKey: 'dataCadastro',
            header: 'Data cadastro',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

          {
            accessorKey: 'dataAtendimento',
            header: 'Data para atendimento',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          


          {
            accessorKey: 'demanda_id',
            header: 'Compra',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'numeroProtheus',
            header: 'Protheus',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
              
          },

          {
            accessorKey: 'processo',
            header: 'Processo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          


          {
            accessorKey: 'descricao',
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
         
          {
            accessorKey: 'solicitante',
            header: 'Solicitante',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },



         
         
        ],
        [],
      );


      const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

          //console.log(result.data)

            setResultadoSolicitantes(result.data.map((rs) => 

                <option value={rs.solicitante}>{rs.solicitante} [{rs.demandaSolicitante_id}]</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const carregaCompradores = () => {

        api.get('compradores').then((result) => {

            setResultadoCompradores(result.data.map((rs) => 

                <option value={rs.usuario_id}>{rs.nome}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const carregaStatus = () => {

        api.get('statusDemanda').then((result) => {

            setResultadoStatus(result.data.map((rs) => 

                <option value={rs.demandaStatus_id}>{rs.fase}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


      const carrega = () => {
        
        setMostraResultado(false)


          if (tipoPeriodo != '') {

            if (dataInicio == '' || dataTermino == '') {

                window.toastr.error('Por favor, informe a <b>Data início</b> e <b>Data Término</b> para consultas por período!');
                return false;

            }

          }
          
          if (dataInicio != '' && dataInicio != null) {
                  
            if (moment(dataInicio, "DD/MM/YYYY", true).isValid() == false) {

                window.toastr.error('<b>Data início</b> inv&aacute;lida!');
                return false;

            }

          }

          if (dataTermino != '' && dataTermino != null) {
                  
            if (moment(dataTermino, "DD/MM/YYYY", true).isValid() == false) {

                window.toastr.error('<b>Data término</b> inv&aacute;lida!');
                return false;

            }

          }


        
        var dataInicioP = ''

        if (dataInicio != '' && dataInicio != null) {

            //var dataInicioArray = dataInicio.split('/')
            var dataInicioArray = moment(dataInicio).format('DD/MM/YYYY').split('/')
            var dataInicioP = dataInicioArray[2] + '-' + dataInicioArray[1] + '-' + dataInicioArray[0]


        }


        var dataTerminoP = ''

        if (dataTermino != '' && dataTermino != null) {

            //var dataTerminoArray = dataTermino.split('/')
            var dataTerminoArray = moment(dataTermino).format('DD/MM/YYYY').split('/')
            var dataTerminoP = dataTerminoArray[2] + '-' + dataTerminoArray[1] + '-' + dataTerminoArray[0]
        }



          var dataPost = {

            demandaStatus_id: demandaStatus_id,
            //comprador: comprador,
            usuario_id_comprador: usuario_id_comprador,
            solicitante: solicitante,
            ano: ano,
            modalidade_id: modalidade_id,
            dataInicio: dataInicioP,
            dataTermino: dataTerminoP,
            tipoPeriodo: tipoPeriodo,
            numeroProtheus: numeroProtheus,
            processo: processo,
            identificacao: identificacao,
            tipoProcesso: tipoProcesso,
          }
  
          //console.log(dataPost)
          //return false

          
  
          api.post('demandas', dataPost).then((result) => {
  
              //var data = result.data

              //console.log(result.data)

              setMostraResultado(true)
  
              setResultado(result.data[0])
              setTotalProcessos(result.data[1][0].totalProcessos)
  
          }).catch((err) => {
  
              console.log(err.response)
  
          })

      }


      const carregaModalidades = () => {

        api.get('modalidades').then((result) => {

            setResultadoModalidades(result.data.map((rs) => 

                <option value={rs.modalidade_id}>{rs.modalidade} [{rs.modalidade_id}]</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


      async function exportaExcel() {


        if (tipoPeriodo != '') {

          if (dataInicio == '' || dataTermino == '') {

              window.toastr.error('Por favor, informe a <b>Data início</b> e <b>Data Término</b> para consultas por período!');
              return false;

          }

        }
        
        if (dataInicio != '') {
                
          if (moment(dataInicio, "DD/MM/YYYY", true).isValid() == false) {

              window.toastr.error('<b>Data início</b> inv&aacute;lida!');
              return false;

          }

        }

        if (dataTermino != '') {
                
          if (moment(dataTermino, "DD/MM/YYYY", true).isValid() == false) {

              window.toastr.error('<b>Data término</b> inv&aacute;lida!');
              return false;

          }

        }


      
      var dataInicioP = ''

      if (dataInicio != '') {

          var dataInicioArray = dataInicio.split('/')
          var dataInicioP = dataInicioArray[2] + '-' + dataInicioArray[1] + '-' + dataInicioArray[0]
      }


      var dataTerminoP = ''

      if (dataTermino != '') {

          var dataTerminoArray = dataTermino.split('/')
          var dataTerminoP = dataTerminoArray[2] + '-' + dataTerminoArray[1] + '-' + dataTerminoArray[0]
      }



        //return false
  
        window.toastr.info('Aguarde, gerando XLSX...')

        var dataPost = {

          demandaStatus_id: demandaStatus_id,
          //comprador: comprador,
          usuario_id_comprador: usuario_id_comprador,
          solicitante: solicitante,
          ano: ano,
          modalidade_id: modalidade_id,
          dataInicio: dataInicioP,
          dataTermino: dataTerminoP,
          tipoPeriodo: tipoPeriodo,
          numeroProtheus: numeroProtheus,
          processo: processo,
          identificacao: identificacao,
          tipoProcesso: tipoProcesso,

        }
  
        await api.post('demandasComprasXLSX', dataPost).then((result) => {
  
            //console.log(result.data)
  
            if (result.data.status == 'ok') {

              
  
                sleep(1000).then(() => {
  
                    //console.log(result.data[0].fileNameCompleto, '!!')
                    //window.location.href = result.data.arquivo
                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                })
                
  
            }
  
        }).catch((err) => {
  
            console.log(err.response)
  
        })
      }


      /*
      useEffect(() => {

        setResultado([])
        if (demandaStatus_id != '' || comprador != '' || solicitante != '' || modalidade_id != '') {

          carrega()

        }
       
      }, [demandaStatus_id, solicitante, comprador, modalidade_id])

      */

      const limparFiltros = () => {

        setDemandaStatus_id('')
        setSolicitante('')
        setComprador('')
        setUsuarioId_comprador('')
        setModalidade_id('')
        setTipoPeriodo('')
        setDataInicio('')
        setDataTermino('')
        setNumeroProtheus('')
        setAno(anoAtual.toString())
        //setAno()
        setProcesso('')
        setIdentificacao('')
        setSolicitante(sessionStorage.getItem('solicitante'))


      }

      useEffect(() => {

        //carrega()
        carregaCompradores()
        carregaSolicitantes()
        carregaStatus()
        carregaAnos()
        carregaModalidades()
        limparFiltros()

      }, [])


    return (

        <div>
          

              <button type="button" class="btn btn-inline btn-primary" onClick={() => {setDemanda_id();setShow(true)} } style={{ display: perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'none' : 'table-row' }}>+ Novo</button>
              <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>
              <button type="button" class="btn btn-inline btn-info" onClick={() => {window.$('.modal-CompraImporta').modal('show')}} style={{ display: perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'none' : 'table-row' }}>+ Importar compras</button>
              <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('#modalAlteraSituacaoCompra').modal('show')}} style={{ display: perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'none' : 'table-row' }}>Alterar situação de processo</button>
              
                      
              <br/><br/>


            <div className="row">


              <div className="col-md-2 col-sm-6" >
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">Ano</label>
                      <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                          <option value="">[Selecione]</option>
                          {listaAnos}
                      </select>
                      
                  </fieldset>
              </div>
              <div className="col-md-2 col-sm-6" >
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">N<sup>o</sup> Protheus</label>
                      <input type="text" className="form-control" value={numeroProtheus} onChange={event => setNumeroProtheus(event.target.value)} />
                      
                      
                  </fieldset>
              </div>
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Fase</label>
                        <select type="text" className="form-control" value={demandaStatus_id} onChange={event => setDemandaStatus_id(event.target.value)}>
                            <option value="">[Selecione]</option>
                            {resultadoStatus}
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Solicitante</label>
                        <select className="form-control" value={solicitante} onChange={event => setSolicitante(event.target.value)} disabled={ perfil == 'ConsultaCompras' ? true : false}>
                            <option value="">[Selecione]</option>
                            {resultadoSolicitantes}
                        
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Comprador</label>
                        <select className="form-control" value={usuario_id_comprador} onChange={event => setUsuarioId_comprador(event.target.value)} >
                            <option value="">[Selecione]</option>
                            {resultadoCompradores}
                        
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" >Modalidade</label>
                        <select className="form-control" value={modalidade_id} onChange={event => setModalidade_id(event.target.value)} >
                            <option value="">[Selecione]</option>
                            {resultadoModalidades}
                        
                        </select>
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-6">
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">Processo</label>
                      <input type="text" className="form-control" value={processo} onChange={event => setProcesso(event.target.value)} />
                      
                      
                  </fieldset>
              </div>
              <div className="col-md-2 col-sm-6">
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">N<sup>o</sup> solicit./ID</label>
                      <input type="text" className="form-control" value={identificacao} onChange={event => setIdentificacao(event.target.value)} />
                      
                      
                  </fieldset>
              </div>
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" >Tipo de período</label>
                        <select className="form-control" value={tipoPeriodo} onChange={event => setTipoPeriodo(event.target.value)} >
                            <option value="">[Selecione]</option>
                            <option value="C">Compras</option>
                            <option value="H">Movimento</option>
                            <option value="D">Cadastro</option>
                        
                        </select>
                            
                        
                    </fieldset>
                </div>
                
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" >Início</label>
                        <DatePicker   selected={dataInicio || null}  onChange={(date) => setDataInicio(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                          customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" /> } 
                          />
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" >Término</label>
                        
                        <DatePicker   selected={dataTermino || null}  onChange={(date) => setDataTermino(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                          customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" /> } 
                          />                   
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-6" >
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">Tipo de processo</label>
                      <select type="text" className="form-control" value={tipoProcesso} onChange={event => setTipoProcesso(event.target.value)}>
                          <option value="">[Selecione]</option>
                          <option value="D">Digital</option>
                          <option value="F">Físico</option>
                      </select>
                      
                  </fieldset>
              </div>

                <div className="col-md-12 col-sm-6">
                  <div align="right">
                  <button type="button" class="btn btn-inline btn-warning" onClick={() => limparFiltros()} >Limpar filtros</button>&nbsp;
                  <button type="button" class="btn btn-inline btn-info" onClick={() => carrega()} >Consultar</button>
                </div>
                </div>



            </div>


            <div style={{ display: mostraResultado == true ? 'block' : 'none' }}>

              <MaterialReactTable 
                  
                  columns={columns} 
                  data={resultado} 
                  localization={MRT_Localization_PT_BR}
                  //rowsPerPage={20}
                  //options={options}
                  initialState={{ density: 'compact' }}
                  muiTableHeadCellProps={{
                      //easier way to create media queries, no useMediaQuery hook needed.
                      sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                    }}
                  muiTableBodyCellProps={{
                      sx: {
                          fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                          },
                        },
                  }}

                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      //console.info(event, row.original.usuario_id);
                      //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                      //window.$('.modal-DemandaCompra').modal('show');setDemanda_id(row.original.demanda_id)
                      setShow(true);setDemanda_id(row.original.demanda_id);
                      //navigate(`/Compra/${row.original.demanda_id}`)

                    },
                    sx: {
                      cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                  
              />
              <br/>
              
              <span class={`label label-info`}>{totalProcessos}</span> processo(s)

          </div>


        <ModalCompraImporta />
        {/* <ModalDemandaCompra demanda_id={demanda_id} carregaDemandas={carrega} /> */}
        <ModalAlteraSituacaoCompras carregaDemandas={carrega} />


          <Modal
            show={show}
            dialogClassName="modal-fullscreen-custom" // Aplica o estilo ao dialog
            contentClassName="modal-content-custom" // Se precisar de mais personalização
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
            
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Compra {demanda_id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
                  
                  <Compra demanda_id={demanda_id} carregaDemandas={carrega} show={setShow} />


            </Modal.Body>
          </Modal>


        </div>
    )

   
}

export default ComprasDemandas