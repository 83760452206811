
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router';
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Fornecedor = () => {

  const navigate = useNavigate()

  const [resultado, setResultado] = useState([])


  const columns = useMemo(
    () => [


      {
        accessorKey: 'cnpjCpf', //access nested data with dot notation
        header: 'CNPJ/CPF',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },

      },
      {
        accessorKey: 'razaoSocial', //access nested data with dot notation
        header: 'Razão Social',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },

      },

    ],
    [],
  );



  const consulta = () => {

    api.get('fornecedorListaTodos').then((result) => {

      var data = result.data


      setResultado(data)

    }).catch((err) => {


      console.log(err.response)


    })


  }




  useEffect(() => {

    consulta()

  }, [])


  return (


    <div>

      <h5 className="m-t-lg with-border">Fornecedores</h5>

      <button type="button" class="btn btn-inline btn-primary" onClick={() => navigate('/Fornecedor')}>Novo</button>



      <br /><br />


      <MaterialReactTable

        columns={columns}
        data={resultado}
        localization={MRT_Localization_PT_BR}
        //rowsPerPage={20}
        //options={options}
        initialState={{ density: 'compact' }}
        muiTableHeadCellProps={{
          //easier way to create media queries, no useMediaQuery hook needed.
          sx: {
            fontSize: {
              xs: '8px',
              sm: '9px',
              md: '10px',
              lg: '11px',
              xl: '12px',
            },
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: {
              xs: '8px',
              sm: '9px',
              md: '10px',
              lg: '11px',
              xl: '12px',
            },
          },
        }}
        
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            //console.info(event, row.original.usuario_id);
            navigate(`/Fornecedor/${row.original.fornecedor_id}`)

          },
          sx: {
            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
          },
        })}

      />


    </div>


  )

}

export default Fornecedor