import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { fmtMoney } from '../functions/generalFunctions'
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import moment from 'moment'

import ModalReservaRecursos from '../modal/ModalReservaRecursos'



const ComprasReservaRecursos = () => {

    const [resultado, setResultado] = useState([])
    const perfil = sessionStorage.getItem('perfil')

    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    const [listaAnos, setListaAnos] = useState()
    const [situacao, setSituacao] = useState('P')

    const [pedidoCompra_id, setPedidoCompra_id] = useState()

    const columns = useMemo(
        () => [

        {
            accessorKey: 'numeroPedido',
            header: 'Pedido',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },

        {
            accessorKey: 'solicitante',
            header: 'Solicitante',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            
        },

        {
            accessorKey: 'valorAutorizado',
            header: 'Valor autorizado',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            
        },
        


        {
            accessorKey: 'situacao',
            header: 'Situação',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == 'Assinado' ? 'success' : renderedCellValue == 'Pendente de assinatura' ? 'warning' : '' }`}>{renderedCellValue}</span>
        
            ),
            
        },


        {
            accessorKey: 'dataAssinaturaReservaRecurso',
            header: 'Assinado em',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            
        },
        
        {
            accessorKey: 'nomeAssinaturaReservaRecurso',
            header: 'Por',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            
        },


        
        
        ],
        [],
    );



    function carregaAnos() {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }


        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carrega = () => {

        const dataPost = {

            ano: ano,
            situacao: situacao,

        }

        //console.log(dataPost)

        api.post('listaReservaRecursos', dataPost).then((result) => {

            setResultado(result.data.map((rs) => {


                return {

                    numeroPedido: rs.numeroPedido,
                    pedidoCompra_id: rs.pedidoCompra_id,
                    solicitante: rs.solicitante,
                    situacao: rs.assinadoReservaRecurso == true ? 'Assinado' : 'Pendente de assinatura',
                    valorAutorizado: rs.valorAutorizado ? fmtMoney(rs.valorAutorizado, 2).replaceAll('R$', '') : '',
                    dataAssinaturaReservaRecurso: rs.dataAssinaturaReservaRecurso,
                    nomeAssinaturaReservaRecurso: rs.nomeAssinaturaReservaRecurso,

                }

            }))

        }).catch((err) => {

            console.log(err)

        })


    }

    
    useEffect(() => {

        carregaAnos()
        

    }, [])


    
    useEffect(() => {

        carrega()
        

    }, [ano, situacao])




    return (

        <div>

            
            <div className="row">



              <div className="col-md-3 col-sm-6" >
                  <fieldset className="form-group">
                      
                      <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                          <option value="">[Ano]</option>
                          {listaAnos}
                      </select>
                      
                  </fieldset>
              </div>
              <div className="col-md-9 col-sm-6" >
                  <fieldset className="form-group">
                      
                    <select className="form-control" value={situacao} onChange={event => setSituacao(event.target.value)} >

                        <option value="P">Pendentes de assinatura</option>
                        <option value="A">Assinados</option>

                    </select>
                      
                      
                  </fieldset>
              </div>


                <div className="col-md-12 col-sm-6">

                    <MaterialReactTable 
                        
                        columns={columns} 
                        data={resultado} 
                        localization={MRT_Localization_PT_BR}
                        //rowsPerPage={20}
                        //options={options}
                        initialState={{ density: 'compact' }}
                        muiTableHeadCellProps={{
                            //easier way to create media queries, no useMediaQuery hook needed.
                            sx: {
                            fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                            },
                            },
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                                },
                            },
                        }}

                        muiTableBodyRowProps={({ row }) => ({
                        onClick: (event) => {
                            //console.info(event, row.original.usuario_id);
                            //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                            //window.$('.modal-DemandaCompra').modal('show');setDemanda_id(row.original.demanda_id)
                            window.$('#modalReservaRecurso').modal('show');setPedidoCompra_id(row.original.pedidoCompra_id)

                        },
                        sx: {
                            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                        },
                        })}
                        
                    />

                </div>


            </div>

            <ModalReservaRecursos pedidoCompra_id={pedidoCompra_id} carregaReservaRecursos={carrega} />

        </div>

    )

}


export default ComprasReservaRecursos